<script setup lang="ts">
// @ts-nocheck
import type { CmsSlot } from '@shopware-pwa/types';
import type { VNodeArrayChildren } from 'vue';
import {
    Thumbs,
    Autoplay,
    FreeMode,
    Navigation as NavigationModule,
    Pagination as PaginationModule,
    Mousewheel,
    Keyboard,
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperClass, SwiperOptions } from 'swiper/types';
import 'swiper/scss';
import 'swiper/scss/free-mode';
import type { ExtendedSliderElementConfig } from '~/components/product/ProductSlider.vue';

export type SliderType =
    | 'lightbox'
    | 'lightbox-thumbnail'
    | 'thumbnail'
    | 'basic'
    | 'gallery'
    | 'tabGroup'
    | 'video'
    | 'boxes-with-shadow'
    | 'stage-banner';

const props = withDefaults(
    defineProps<{
        config: ExtendedSliderElementConfig;
        sliderOptions?: SwiperOptions;
        navigation?: boolean; // exclude navigation from sliderOptions to handle navigation on our own
        pagination?: boolean; // exclude pagination from sliderOptions to handle pagination on our own
        slotDepth?: 1 | 2; // slotDepth=2 needs to be used if direct child of slider is a slot
        wrapperPadding?: boolean;
        watchSlidesProgress?: boolean;
        type?: SliderType;
    }>(),
    {
        sliderOptions: undefined,
        navigation: true,
        pagination: true,
        slotDepth: 1,
        wrapperPadding: false,
        watchSlidesProgress: undefined,
        type: 'basic',
    },
);

defineEmits<{
    (
        e: 'changeSlide' | 'changeSlideTransitionStart' | 'changeSlideTransitionEnd' | 'onInit',
        slider: SwiperClass,
    ): void;
    (e: 'slidePrevTransitionEnd' | 'slideNextTransitionEnd'): void;
}>();

const modules = [NavigationModule, PaginationModule, FreeMode, Autoplay, Thumbs, Mousewheel, Keyboard];

const { getConfigValue } = useCmsElementConfig({
    config: props.config,
} as CmsSlot & {
    config: ExtendedSliderElementConfig;
});

const verticalAlignValue = computed(() => getConfigValue('verticalAlign') || 'flex-start');
const navigationArrowsValue = computed(() => props.config?.navigationArrows?.value || 'none');
const wrapperPadding = computed(() => props.config?.wrapperPadding?.value || 'none');

const slots = useSlots();

const children = computed(() => {
    const defaultSlots = slots?.default && typeof slots?.default === 'function' ? slots.default() : [];

    if (!defaultSlots || defaultSlots.length === 0) {
        return [];
    } else if (props.slotDepth === 1) {
        let childrenArray = [];

        // there are multiple default slots if SharedSlider has multiple child nodes
        defaultSlots.forEach((slot) => {
            childrenArray = [...childrenArray, ...slot.children];
        });

        return childrenArray as VNodeArrayChildren;
    } else if (props.slotDepth === 2) {
        return (defaultSlots[0].children?.[0]?.children ?? []) as VNodeArrayChildren;
    }

    return [];
});

const {
    sliderInstance,
    slidesPerView,
    snapIndex,
    snapCount,
    isEnd,
    isBeginning,
    isReady,
    onSliderInit,
    onSlideChange,
    onSlideChangeTransitionStart,
    onSlideChangeTransitionEnd,
    onSlidePrevTransitionEnd,
    onSlideNextTransitionEnd,
    onSnapGridLengthChange,
    onBreakpointChange,
    onResize,
    onTouchEnd,
    prev,
    next,
} = useSlider();

onBeforeUnmount(() => {
    sliderInstance.value?.destroy();
});
</script>

<template>
    <ClientOnly>
        <div
            v-if="children && children.length > 0"
            v-bind="$attrs"
            class="flex"
            :class="{
                'h-full': true,
                'items-center': verticalAlignValue === 'center',
                'items-start': verticalAlignValue === 'flex-start',
                'items-end': verticalAlignValue === 'flex-end',
                'opacity-0': !isReady && children && children.length > slidesPerView,
            }"
        >
            <div
                class="relative"
                :class="{
                    'px-10 xl:px-0':
                        navigationArrowsValue === 'outside' &&
                        children &&
                        children.length > slidesPerView &&
                        type !== 'thumbnail' &&
                        type !== 'video',
                    'px-10 md:px-17': wrapperPadding === true && children && children.length > slidesPerView,
                    'mb-10 md:mb-12': pagination && type === 'tabGroup' && snapCount > 1,
                    'tabs-container w-full': type === 'tabGroup',
                    'w-full': type !== 'tabGroup',
                    'h-full w-full flex': type === 'lightbox',
                }"
            >
                <Swiper
                    v-bind="props.sliderOptions as any"
                    :modules="modules"
                    :class="{
                        'w-full': type === 'gallery' || type === 'video',
                        '!p-1.85 !-m-1.85': type === 'boxes-with-shadow',
                        '!p-0': type !== 'basic' && type !== 'boxes-with-shadow' && type !== 'tabGroup',
                    }"
                    :watch-slides-progress="watchSlidesProgress"
                    @swiper="onSliderInit"
                    @slide-change="onSlideChange"
                    @slide-change-transition-start="onSlideChangeTransitionStart"
                    @slide-change-transition-end="onSlideChangeTransitionEnd"
                    @slide-prev-transition-end="onSlidePrevTransitionEnd"
                    @slide-next-transition-end="onSlideNextTransitionEnd"
                    @snap-grid-length-change="onSnapGridLengthChange"
                    @breakpoint="onBreakpointChange"
                    @resize="onResize"
                    @touch-end="onTouchEnd"
                >
                    <SwiperSlide
                        v-for="(child, index) of children"
                        :key="index"
                        :class="{ '!w-auto': sliderOptions && sliderOptions.slidesPerView === 'auto' }"
                    >
                        <component
                            :is="child"
                            class="overflow-hidden"
                        />
                    </SwiperSlide>
                </Swiper>

                <div
                    v-if="navigationArrowsValue === 'bottom'"
                    class="flex gap-3 justify-between items-center mt-5 md:px-3"
                    :class="{
                        '!px-0': type === 'boxes-with-shadow',
                        'mt-8': type === 'stage-banner' || type === 'lightbox',
                        'mt-4': type === 'video',
                    }"
                >
                    <div
                        v-if="pagination && snapCount > 1"
                        class="relative shrink-1 grow-1"
                    >
                        <SharedPaginationProgress
                            :current="snapIndex + 1"
                            :total="snapCount"
                            :text="false"
                        />
                    </div>
                    <div
                        v-if="navigation && snapCount > 1"
                        class="flex gap-3 shrink-0 grow-0 ml-auto"
                        :class="{ 'md:absolute md:right-0': type !== 'lightbox-thumbnail' }"
                    >
                        <SharedButton
                            color="white"
                            class="touch-none"
                            :rounded="true"
                            :title="$t('previous')"
                            :disabled="isBeginning"
                            @click="prev"
                        >
                            <SvgoArrowLeftLong class="w-auto h-5.5" />
                        </SharedButton>
                        <SharedButton
                            color="white"
                            class="touch-none"
                            :rounded="true"
                            :title="$t('next')"
                            :disabled="isEnd"
                            @click="next"
                        >
                            <SvgoArrowRightLong class="h-5.5 w-auto" />
                        </SharedButton>
                    </div>
                </div>

                <template v-else>
                    <slot
                        name="navigation"
                        :navigation="navigation"
                        :prev="prev"
                        :next="next"
                        :navigation-arrows-value="navigationArrowsValue"
                    >
                        <template v-if="navigation && snapCount > 1">
                            <div
                                :class="{
                                    'py-3 pr-3 sm:py-8 sm:pr-8 lg:py-3 lg:pr-3 xl:py-8 xl:pr-8 2xl:py-12 2xl:pr-12':
                                        type === 'gallery',
                                    'absolute top-1/2 -translate-y-1/2 align-items-center justify-content-center z-4': true,
                                    'left-0 xl:-left-20': navigationArrowsValue === 'outside' && type !== 'thumbnail',
                                    'left-0 -translate-x-1/2': type === 'thumbnail',
                                    'left-0 m-1':
                                        navigationArrowsValue === 'inside' || navigationArrowsValue === 'none',
                                    '!hidden': navigationArrowsValue === 'hidden',
                                    '!left-0 xl:!left-5': type === 'lightbox',
                                }"
                            >
                                <SharedButton
                                    color="white"
                                    class="touch-none"
                                    :rounded="true"
                                    :title="$t('previous')"
                                    :disabled="isBeginning"
                                    @click="prev"
                                >
                                    <SvgoArrowLeftLong class="w-auto h-5.5" />
                                </SharedButton>
                            </div>

                            <div
                                :class="{
                                    'py-3 pl-3 sm:py-8 sm:pl-8 lg:py-3 lg:pl-3 xl:py-8 xl:pl-8 2xl:py-12 2xl:pl-12':
                                        type === 'gallery',
                                    'absolute top-1/2 -translate-y-1/2 align-items-center justify-content-center z-4': true,
                                    'right-0 xl:-right-20': navigationArrowsValue === 'outside' && type !== 'thumbnail',
                                    'right-0 translate-x-1/2': type === 'thumbnail',
                                    'right-0 m-1':
                                        navigationArrowsValue === 'inside' || navigationArrowsValue === 'none',
                                    '!hidden': navigationArrowsValue === 'hidden',
                                    '!right-0 xl:!right-5': type === 'lightbox',
                                }"
                            >
                                <SharedButton
                                    color="white"
                                    class="touch-none"
                                    :rounded="true"
                                    :title="$t('next')"
                                    :disabled="isEnd"
                                    @click="next"
                                >
                                    <SvgoArrowRightLong class="h-5.5 w-auto" />
                                </SharedButton>
                            </div>
                        </template>
                    </slot>

                    <slot name="pagination">
                        <div
                            v-if="pagination && snapCount > 1"
                            class="flex justify-center w-full z-1"
                            :class="[type === 'tabGroup' ? 'absolute left-0 right-0 top-full px-5' : 'relative']"
                        >
                            <SharedPaginationProgress
                                :current="sliderInstance.isEnd ? snapCount : snapIndex + 1"
                                :total="snapCount"
                                :text="false"
                            />
                        </div>
                    </slot>
                </template>
            </div>
        </div>

        <template #fallback>
            <slot name="loader" />
        </template>
    </ClientOnly>
</template>

<style scoped lang="scss">
.swiper {
    padding: 15px 12px 15px 15px;
}
</style>
