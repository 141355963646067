import { computed, type ComputedRef, getCurrentInstance, type Ref, ref } from 'vue';
import type { Swiper as SwiperClass } from 'swiper/types';

export type UseSliderReturn = {
    sliderInstance: Ref<SwiperClass>;
    slidesPerView: ComputedRef<number>;
    isEnd: ComputedRef<boolean>;
    isBeginning: ComputedRef<boolean>;
    isReady: Ref<boolean>;
    onSliderInit: (slider: SwiperClass) => void;
    onSlideChange: (slider: SwiperClass) => void;
    onSlideChangeTransitionStart: (slider: SwiperClass) => void;
    onSlideChangeTransitionEnd: (slider: SwiperClass) => void;
    onSlidePrevTransitionEnd: () => void;
    onSlideNextTransitionEnd: () => void;
    onBreakpointChange: (slider: SwiperClass) => void;
    onResize: (slider: SwiperClass) => void;
    onTouchEnd: (slider: SwiperClass) => void;
    currentIndex: ComputedRef<number>;
    prev: () => void;
    next: () => void;
    goTo: (index: number) => void;
};

export function useSlider(): UseSliderReturn {
    const { emit } = getCurrentInstance() as any;
    const sliderInstance = ref<SwiperClass>();
    const isReady = ref<boolean>();
    const _activeIndex = ref<number>(0);
    const _loop = ref<boolean>(false);
    const _isBeginning = ref<boolean>(false);
    const _isEnd = ref<boolean>(false);
    const _slidesPerView = ref<number>(1);
    const _snapIndex = ref<number>(0);
    const _snapCount = ref<number>(0);

    const _updateStats = async (slider: SwiperClass) => {
        await nextTick();
        sliderInstance.value = slider;
        _loop.value = !!slider?.params?.loop;
        _activeIndex.value = slider.activeIndex;
        _isBeginning.value = _loop.value ? false : slider.isBeginning;
        _isEnd.value = _loop.value ? false : slider.isEnd;

        const newSlidesPerView = slider.params?.slidesPerView;
        _slidesPerView.value = !newSlidesPerView || newSlidesPerView === 'auto' ? 1 : newSlidesPerView;
        _snapIndex.value = slider.snapIndex;
        _snapCount.value = slider.snapGrid?.length || 0;
    };

    const onSliderInit = (slider: SwiperClass) => {
        _updateStats(slider);
        isReady.value = true;
        emit('onInit', slider);
    };

    const onSlideChange = (slider: SwiperClass) => {
        _updateStats(slider);
        emit('changeSlide', slider);
    };

    const onSlideChangeTransitionStart = (slider: SwiperClass) => {
        emit('changeSlideTransitionStart', slider);
    };

    const onSlideChangeTransitionEnd = (slider: SwiperClass) => {
        emit('changeSlideTransitionEnd', slider);
    };

    const onSlidePrevTransitionEnd = () => {
        emit('slidePrevTransitionEnd');
    };

    const onSlideNextTransitionEnd = () => {
        emit('slideNextTransitionEnd');
    };

    const onSnapGridLengthChange = (slider: SwiperClass) => {
        _updateStats(slider);
    };

    const onBreakpointChange = (slider: SwiperClass) => {
        _updateStats(slider);
    };

    const onResize = (slider: SwiperClass) => {
        _updateStats(slider);
    };

    const onTouchEnd = (slider: SwiperClass) => {
        _updateStats(slider);
    };

    const prev = () => {
        sliderInstance.value?.slidePrev();
    };
    const next = () => {
        sliderInstance.value?.slideNext();
    };
    const goTo = (index: number) => {
        sliderInstance.value?.slideTo(index + 1);
    };

    return <UseSliderReturn>{
        sliderInstance,
        slidesPerView: computed(() => _slidesPerView.value),
        snapIndex: computed(() => _snapIndex.value),
        snapCount: computed(() => _snapCount.value),
        isEnd: computed(() => _isEnd.value),
        isBeginning: computed(() => _isBeginning.value),
        isReady,
        onSliderInit,
        onSlideChange,
        onSlideChangeTransitionStart,
        onSlideChangeTransitionEnd,
        onSlidePrevTransitionEnd,
        onSlideNextTransitionEnd,
        onSnapGridLengthChange,
        onBreakpointChange,
        onResize,
        onTouchEnd,
        currentIndex: computed(() => _activeIndex.value),
        prev,
        next,
        goTo,
    };
}
